<template >
  <div class="callback" style="padding-bottom: 120px;">
    <div class="container"> 
      <div class="callback__block">
        <div class="callback__img">
          <img src="@/assets/img/callback.webp" alt="">
        </div>
        <div class="callback__content">
            <h2 class="callback__title"> Хотите узнать больше о наших принтерах или материалах?</h2>
            <p class="callback__text"> Укажите свои контактные данные и наш менеджер свяжется с вами для консультации</p>
            <form action="#" class="callback__form">
              <div class="callback__input">
                <input type="text" placeholder="Введите номер телефона">
                
              </div>
              <button type="submit" class="btn btn--red btn--big"> Отправить заявку</button>
            </form>
        </div>
      </div>
    </div>
  </div>
</template>