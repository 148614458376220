<template>
<div class="page">
  <header-block/>
  <ContactPage/>
  <WelcomeBlock/>
  <footer-block/>
</div>
</template>

<script>
// @ is an alias to /src
import HeaderBlock from '../components/HeaderBlock.vue'
import ContactPage from '../components/ContactPage.vue'
import FooterBlock from '../components/FooterBlock.vue'
import WelcomeBlock from "../components/elements/Blocks/WelcomeBlock.vue"

export default {
  name: 'Contact',
  components: {
    ContactPage,
    FooterBlock,
    HeaderBlock,
    WelcomeBlock
  }
}
</script>
