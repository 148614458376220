<template>
  <div class="page">
    <header-block />
    <NewsPage />
    <WelcomeBlock/>
    <footer-block />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderBlock from '../components/HeaderBlock.vue'
import NewsPage from '../components/NewsPage.vue'
import FooterBlock from '../components/FooterBlock.vue'
import WelcomeBlock from "../components/elements/Blocks/WelcomeBlock.vue"

export default {
  name: 'News',
  components: {
    NewsPage,
    FooterBlock,
    HeaderBlock,
    WelcomeBlock
  }
}
</script>
