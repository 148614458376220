<template>
    <div class="welcome">
      <div class="container">
        <div class="welcome__content">
          <router-link class="" to="/study">
            <div class="shop inline">
              <a class="menu__btn menu__btn--red" href="#" style="margin-top: 500px; margin-left: auto; margin-right: auto; position: relative; ">
                <div class="menu__btn-content">
                  <div class="menu__btn-title" style="font-size: 2em;">Магазин</div>
                  <div class="menu__btn-text">Каталог 3Д принтеров и Материалов</div>
                </div>
              </a>
            </div>
          </router-link>
          <router-link class="" to="/study">
            <div class="print inline">
              <a class="menu__btn menu__btn--red" href="#" style="margin-top: 500px; position: relative;">
                <div class="menu__btn-content">
                  <div class="menu__btn-title" style="font-size: 2em;">Заказать 3D печать</div>
                  <div class="menu__btn-text">Заказ на 3Д печать онлайн</div>
                </div>
              </a>
            </div>
          </router-link>
          <router-link class="" to="/study">
            <div class="study inline">
              <a class="menu__btn menu__btn--red" href="#" style="margin-top: 500px; position: relative;">
                
                <div class="menu__btn-content">
                  <div class="menu__btn-title" style="font-size: 2em;">Пройти обучение</div>
                  <div class="menu__btn-text">Получи диплом специаилиста по 3D печати</div>
                </div>
              </a>
            </div>
          </router-link>
        </div>
      </div>
    </div>
</template>
<style scoped>
    .inline {
    display: inline-block;
    padding: 10px;
    }
    .inline {
    transition: 1s; /* Время эффекта */
    }
    .inline:hover {
      transform: scale(1.05); /* Увеличиваем масштаб */
    }
    .shop {
    height: 500px;
    width: 33%;
    background-image: url(../../../assets/img/shop_2.png);
    background-size: 100% auto;
    justify-content: center;
    display: inline-block;
    align-items: center;
    background-repeat: no-repeat;
    }
    .print {
    height: 500px;
    width: 33%;
    background-image: url(../../../assets/img/print_2.png);
    background-size: 100% auto;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    }
    .study {
    height: 500px;
    width: 33%;
    background-image: url(../../../assets/img/study_2.png);
    background-size: 100% auto;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    }
</style>