<template>
  <div class="page">
    <header-block />
    <ArticlePage />
    <WelcomeBlock/>
    <footer-block />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderBlock from '../components/HeaderBlock.vue'
import ArticlePage from '../components/ArticlePage.vue'
import FooterBlock from '../components/FooterBlock.vue'
import WelcomeBlock from "../components/elements/Blocks/WelcomeBlock.vue"

export default {
  name: 'Article',
  components: {
    ArticlePage,
    FooterBlock,
    HeaderBlock,
    WelcomeBlock
  }
}
</script>
